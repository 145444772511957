<template>
  <div class="hire">
    <div class="header">
      <span>手机号：</span>
      <el-input v-model="phone"></el-input>
      <el-button type="primary" @click="selectUser">搜索</el-button>
      <div class="user-box">
        <p>用户名: {{ user.name }}</p>
      </div>
    </div>
    <div class="order-tab" v-if="showTab">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column type="index" label="序号" width="80"> </el-table-column>
        <el-table-column prop="name" label="玩具名"> </el-table-column>
        <el-table-column prop="fineness" label="新旧程度"> </el-table-column>
        <el-table-column label="留存照片">
          <template slot-scope="scope">
            <img class="retainphotos" v-for="item in scope.row.imageList" :key="item" :src="item" alt="" />
          </template>
        </el-table-column>
        <el-table-column prop="years_old" label="适合年龄"> </el-table-column>
        <el-table-column prop="price" label="冻结押金"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button-group>
              <el-button type="primary" icon="el-icon-camera-solid" size="mini" @click="photographGoods(scope.row)">拍照</el-button>
              <el-button type="danger" icon="el-icon-delete-solid" size="mini" @click="deleteGoods(scope.row)">删除</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
      <el-button-group>
        <el-button type="primary" icon="el-icon-circle-plus" @click="addGoods">添加</el-button>
        <el-button v-if="tableData.length > 0" type="primary" @click="payonesbill">保存<i class="el-icon-upload"></i></el-button>
      </el-button-group>
      <div class="deposit">
        <ul>
          <li>可用押金：{{ user.vip.deposit }} 元</li>
          <li>本次冻结：{{ djdeposit }} 元</li>
          <li>剩余押金：{{ sydeposit }} 元</li>
        </ul>
      </div>
    </div>
    <el-empty v-else :description="emptyText"></el-empty>
    <!-- 扫描条形码 -->
    <el-dialog :visible.sync="dialogScan" title="扫码">
      <img class="scanImage" src="../../assets/images/southeast.jpeg" alt="" />
      <el-input ref="inputcode" v-model="scanCode" placeholder="请输入条形码下方数字或扫描条形码" @keyup.enter.native="searchToy"></el-input>
    </el-dialog>
    <!-- 拍照 -->
    <el-dialog :visible.sync="dialogCamera" width="70%">
      <div class="content" v-loading="loading">
        <video ref="video" width="400" height="300" autoplay></video>
        <canvas ref="canvas" width="400" height="300"></canvas>
      </div>
      <div class="ul">
        <transition name="el-zoom-in-center" v-for="(item, index) in imageList" :key="index">
          <div class="img-box">
            <img :src="item" alt="" />
          </div>
        </transition>
      </div>
      <div class="btn-box">
        <el-button @click="photograph">拍摄</el-button>
        <el-button type="success" @click="submitPhotog">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      phone: '',
      user: {
        vip: {},
      },
      emptyText: '搜索会员后添加订单',
      tableData: [],
      showTab: true,
      vipID: '',
      dialogScan: false, // 扫码弹窗
      scanCode: '', // 商品code
      imageList: [],
      dialogCamera: false,
      loading: false,
      goods_id: '',
      djdeposit: 0, // 本次冻结金额
      sydeposit: '', // 剩余押金
    }
  },
  created() {
    this.phone = this.getQueryValue('phone')
    if (this.phone) this.selectUser()
  },
  methods: {
    async payonesbill() {
      if (!this.vipID || !this.phone) return
      let goods = []
      this.tableData.forEach((item) => {
        goods.push({ id: item.id, bar_code: item.bar_code, imageList: item.imageList })
      })
      console.log(goods)
      const res = await this.axios.post('cashier/hire', {
        user_id: this.user.id,
        goods: goods,
      })
      if (res.code === 200) {
        this.$message.success('保存成功')
      } else {
        this.$message.error(res.message)
      }
    },
    // 打开拍照
    photographGoods(row) {
      console.log(row)
      this.goods_id = row.id
      this.dialogCamera = true
      this.imageList = []
      this.callCamera()
    },
    // 拍摄照片
    photograph() {
      this.loading = true
      let ctx = this.$refs['canvas'].getContext('2d')
      ctx.drawImage(this.$refs['video'], 0, 0, 400, 300)
      let imgBase64 = this.$refs['canvas'].toDataURL('image/jpeg')
      this.uploadImage(imgBase64)
    },
    // 拍照上传
    async uploadImage(imgBase64) {
      const res = await this.axios.post('cashier/base64/upload', {
        image: imgBase64,
      })
      if (res.code === 200) {
        this.$message.success('成功')
        this.imageList.push(res.data)
        this.loading = false
      } else {
        this.$message.error('失败！请从新拍照')
      }
    },
    // 保存照片
    submitPhotog() {
      this.tableData.some((item, index) => {
        if (item.id === this.goods_id) {
          this.$set(this.tableData[index], 'imageList', this.imageList)
          this.dialogCamera = false
          return true
        }
      })
    },
    // 打开摄像头
    callCamera() {
      this.loading = true
      navigator.mediaDevices
        .getUserMedia({
          video: true,
        })
        .then((success) => {
          this.$refs['video'].srcObject = success
          this.$refs['video'].play()
          this.loading = false
        })
        .catch((error) => {
          console.error('请检查摄像头是否可用')
        })
    },
    addGoods() {
      this.dialogScan = true
      setTimeout(() => {
        this.$refs.inputcode.focus()
      }, 500)
    },
    async selectUser() {
      const res = await this.axios.get(`cashier/user/recommend/phone?phone=${this.phone}`)
      if (res.code === 200) {
        console.log(res)
        this.showTab = true
        this.vipID = res.data.id
        this.user = res.data
        this.sydeposit = this.user.vip.deposit - this.djdeposit
      } else {
        this.$notify.error({
          title: '提示',
          message: res.message,
        })
      }
    },
    async searchToy() {
      const res = await this.axios.get(`cashier/good/${this.scanCode}`)
      if (res.code === 200) {
        if (this.tableData.filter((item) => item.id === res.data.id).length > 0) {
          this.$message.error('请务重复扫描商品')
          this.scanCode = ''
        } else {
          this.djdeposit = this.djdeposit + Number(res.data.price)
          this.tableData.push(res.data)
          this.scanCode = ''
          this.dialogScan = false
        }
      } else {
        this.$message.error('商品不存在')
        this.scanCode = ''
      }
    },
    // 删除商品
    deleteGoods(row) {
      this.tableData.some((item, index) => {
        if (item.id === row.id) {
          this.tableData.splice(index, 1)
          return true
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.hire {
  .header {
    .el-input {
      width: 20%;
    }
    .user-box {
      padding-top: 20px;
      p {
        font-size: 20px;
      }
    }
  }
  .order-tab {
    margin-top: 20px;
  }
  .scanImage {
    width: 200px;
    margin: auto;
  }
  .btn-box {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
  .content {
    display: flex;
    margin-bottom: 28px;
  }
  .ul {
    display: flex;
    flex-wrap: wrap;
    .img-box {
      width: 200px;
      height: 150px;
      margin-right: 20px;
    }
  }
  /deep/.retainphotos {
    width: 50px;
    float: left;
  }
  .deposit {
    padding-top: 20px;
    ul {
      display: flex;
      align-items: center;
      li {
        font-size: 15px;
        width: 300px;
        display: flex;
        margin-right: 50px;
        align-items: center;

        p {
          width: 100px;
        }
      }
    }
  }
}
</style>
