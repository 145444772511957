<template>
  <div class="order-return">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/something'}">还玩具</el-breadcrumb-item>
      <el-breadcrumb-item>还玩具订单详情</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form :inline="true" :model="formInline" class="demo-form-inline" size="small">
      <el-form-item label="订单号">
        <el-input v-model="formInline.no" placeholder="订单号"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">查询</el-button>
      </el-form-item>
    </el-form>
    <!-- <p>会员姓名:{{order.user.name}}</p>
    <p>手机号:{{order.user.phone}}</p> -->
    <el-divider></el-divider>
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column type="index" label="序号">
      </el-table-column>
      <el-table-column prop="good.name" label="名称">
      </el-table-column>
      <el-table-column prop="good.fineness" label="新旧程度">
      </el-table-column>
      <el-table-column prop="address" label="留存照片">
        <template slot-scope="scope">
          <img v-for="item in scope.row.give_image" :src="item" alt="" class="goods-img">
        </template>
      </el-table-column>
      <el-table-column prop="address" label="归还照片">
        <template slot-scope="scope">
          <img v-for="item in scope.row.back_image" :src="item" alt="" class="goods-img">
        </template>
      </el-table-column>
      <el-table-column label="适合年龄">
        <template slot-scope="scope">
          <span>{{scope.row.good.start_years_old}}-{{scope.row.good.end_years_old}}岁</span>
        </template>
      </el-table-column>
      <el-table-column prop="good.price" label="冻结押金">
      </el-table-column>
      <el-table-column label="扣除押金">
        <template slot-scope="scope">
          <div @click="editDeposit(scope.row)">{{scope.row.delete_deposit}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status == 1">进行中</el-tag>
          <el-tag v-else-if="scope.row.status == 2" type="success">已完结</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="操作员">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini">归还玩具</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 拍照 -->
    <el-dialog :visible.sync="dialogCamera" width="70%">
      <div class="content" v-loading="loading">
        <video ref="video" width="400" height="300" autoplay></video>
        <canvas ref="canvas" width="400" height="300"></canvas>
      </div>
      <div class="ul">
        <transition name="el-zoom-in-center" v-for="(item,index) in imageList" :key="index">
          <div class="img-box">
            <img :src="item" alt="">
          </div>
        </transition>
      </div>
      <div class="btn-box">
        <el-button @click="photograph">拍摄</el-button>
        <el-button type="success" @click="submitPhotog">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>
export default {
  data() {
    return {
      formInline: {
        no: ''
      },
      tableData: [],
      order: {
        user: {},
        info: []
      },
      id: '',
      imageList: [],
      dialogCamera: false,
      loading: false
    }
  },
  created() {
    // this.order = JSON.parse(localStorage.getItem('orderInfo'))
    // this.formInline.no = this.order.no
    // this.tableData = this.order.info
    this.id = this.getQueryValue('id')
    this.getOrder()
  },
  methods: {
    onSubmit() { },
    async getOrder() {
      const res = await this.axios.get(`cashier/hire/${this.id}`)
      console.log(res)
      if (res.code === 200) {
        this.tableData = res.data.info
        this.formInline.no = res.data.no
      }
    },
    editDeposit(row) {
      this.$prompt('请输入扣除的押金', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        row.delete_deposit = value
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    photograph() { },
    submitPhotog() { }
  }
}
</script>


<style lang="less" scoped>
.order-return {
  .el-form {
    margin-top: 25px;
  }
  p {
    line-height: 35px;
    font-size: 14px;
  }
  .goods-img {
    width: 50px;
  }
}
</style>



