<template>
  <div class="memberDetails">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/memberList' }">会员管理</el-breadcrumb-item>
      <el-breadcrumb-item>会员详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="member-header">
      <div class="member-info">
        <div class="img-box">
          <img src="../../assets/images/profile-4.jpg">
        </div>
        <div class="info">
          <p>用户昵称:{{user.name}}</p>
          <p>{{user.phone}}</p>
          <p>{{user.vip ? 'vip用户' : '普通用户'}}</p>
          <p>{{user.openid ? '已关联微信' : '未关联微信'}}</p>
        </div>
      </div>
      <ul>
        <li v-if="user.vip">
          <p>押金余额</p>
          <p>{{user.vip.deposit}}元</p>
          <el-button type="success" size="small" @click="recharge">充值</el-button>
        </li>
        <li v-if="user.vip">
          <p>vip到期时间</p>
          <p>{{user.vip.end_time}}</p>
          <el-button type="success" size="small" @click="renew">续费</el-button>
        </li>
        <li v-if="user.vip">
          <p>租赁进行中订单</p>
          <p>{{user.hire_count}}</p>
          <el-button type="success" size="small">查看</el-button>
        </li>
        <li>
          <p>介绍会员</p>
          <p>{{user.recommends_count}}</p>
          <el-button type="success" size="small">查看</el-button>
        </li>
      </ul>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="会员信息" name="1">
        <el-descriptions title="会员信息" :column="3" border>
          <el-descriptions-item label="用户名" label-class-name="my-label" content-class-name="my-content">{{user.name}}</el-descriptions-item>
          <el-descriptions-item label="手机号">{{user.phone}}</el-descriptions-item>
          <el-descriptions-item label="居住地"></el-descriptions-item>
          <el-descriptions-item label="备注">
            <el-tag size="small" v-if="user.vip">vip会员</el-tag>
            <el-tag size="small" v-else>非vip会员</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="联系地址" :contentStyle="{'text-align': 'left'}"></el-descriptions-item>
        </el-descriptions>
      </el-tab-pane>
      <el-tab-pane label="押金历史" name="2">
        <el-timeline>
          <el-timeline-item v-for="(activity, index) in activities" :key="index" :icon="activity.icon" :type="activity.typeIcon" :color="activity.color" :size="activity.size" :timestamp="activity.timestamp">
            {{activity.content}}
          </el-timeline-item>
        </el-timeline>
        <el-empty v-if="activities.length <= 0" description="暂无押金历史"></el-empty>
      </el-tab-pane>
      <el-tab-pane label="vip续费历史" name="3">
        <el-timeline>
          <el-timeline-item v-for="(activity, index) in vipHistory" :key="index" :icon="activity.icon" :type="activity.typeIcon" :color="activity.color" :size="activity.size" :timestamp="activity.created_at">
            {{activity.content}}
          </el-timeline-item>
        </el-timeline>
        <el-empty v-if="vipHistory.length <= 0" description="暂无续费历史"></el-empty>
      </el-tab-pane>
      <el-tab-pane label="租赁订单" name="4">
        <el-button-group>
          <el-button type="primary" @click="hireGoods">租玩具</el-button>
          <el-button type="success">还玩具</el-button>
        </el-button-group>
        <el-table :data="HireOrderTab" stripe border style="width: 100%">
          <el-table-column type="index" label="序号" width="50">
          </el-table-column>
          <el-table-column prop="created_at" label="日期">
          </el-table-column>
          <el-table-column prop="no" label="订单号">
          </el-table-column>
          <el-table-column label="项目状态">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.status === 1">租借中</el-tag>
              <el-tag v-else-if="scope.row.status === 2" type="success">已归还</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="store.username" label="操作员">
          </el-table-column>
          <el-table-column prop="" label="操作" width="350" fixed="right">
            <template slot-scope="scope">
              <el-button-group>
                <el-button type="primary" @click="clickHireOrderDetails(scope.row)" icon="el-icon-share" size="mini">订单详情</el-button>
                <!-- <el-button type="success" @click="editorderClick(scope.row)" icon="el-icon-edit" size="mini">归还玩具</el-button> -->
                <el-button type="danger" icon="el-icon-delete" size="mini">删除订单</el-button>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="订购订单" name="5">
        <el-table :data="goodsorderTab" stripe border style="width: 100%">
          <el-table-column type="index" label="序号" width="50">
          </el-table-column>
          <el-table-column prop="created_at" label="日期">
          </el-table-column>
          <el-table-column prop="order_no" label="订单号">
          </el-table-column>
          <el-table-column prop="address" label="订单状态">
            <!-- 1下单 2配送 3完成 4取消 -->
            <template slot-scope="scope">
              <el-tag v-if="scope.row.status === 1">下单</el-tag>
              <el-tag v-else-if="scope.row.status === 2" type="success">配送</el-tag>
              <el-tag v-else-if="scope.row.status === 3" type="info">完成</el-tag>
              <el-tag v-else type="danger">取消</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="price" label="价格">
          </el-table-column>
          <el-table-column label="操作" width="350" fixed="right">
            <template slot-scope="scope">
              <el-button-group>
                <el-button type="primary" @click="clickorderDetails(scope.row)" icon="el-icon-share" size="mini">订单详情</el-button>
                <el-button type="success" @click="editorderClick(scope.row)" icon="el-icon-edit" size="mini">修改订单</el-button>
                <el-button type="danger" icon="el-icon-delete" size="mini">删除订单</el-button>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination :page-size="10" layout="prev, pager, next" :total="total" @current-change='chageSize'></el-pagination>
      </el-tab-pane>
      <el-tab-pane label="出售订单" name="6">
        <el-table :data="tableData" stripe border style="width: 100%">
          <el-table-column prop="date" label="序号" width="50">
          </el-table-column>
          <el-table-column prop="date" label="日期">
          </el-table-column>
          <el-table-column prop="name" label="项目">
          </el-table-column>
          <el-table-column prop="address" label="订单号">
          </el-table-column>
          <el-table-column prop="address" label="项目状态">
          </el-table-column>
          <el-table-column prop="address" label="操作员">
          </el-table-column>
          <el-table-column prop="address" label="操作">
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="介绍会员" name="7">介绍会员</el-tab-pane>
    </el-tabs>
    <!-- 订单详情 -->
    <el-dialog title="租赁订单详情" :visible.sync="dialogTableVisible" width="70%">
      <el-table :data="hireOrderDetails">
        <el-table-column type="index" label="序号" width="80"></el-table-column>
        <el-table-column property="created_at" label="日期"></el-table-column>
        <el-table-column property="name" label="名称"></el-table-column>
        <el-table-column label="项目状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status == 1">租借中</el-tag>
            <el-tag v-else-if="scope.row.status == 2" type="success">已归还</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="250">
          <template slot-scope="scope">
            <el-button-group v-if="scope.row.status == 1">
              <el-button type="success" @click="returning(scope.row)" icon="el-icon-edit" size="mini">归还玩具</el-button>
              <el-button type="danger" icon="el-icon-delete" size="mini">丢失</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>


<script>
export default {
  data() {
    return {
      uid: '',
      activeName: '1',
      activities: [],
      vipHistory: [],
      tableData: [],
      user: {},
      depositType: ['添加', '租借', '扣除', '退回', '提现', '推荐'],
      page: 1,
      total: 0,
      goodsorderTab: [],
      HireOrderTab: [],
      // 租赁订单详情
      dialogTableVisible: false,
      hireOrderDetails: []
    }
  },
  created() {
    this.uid = this.getQueryValue('id')
    if (this.uid) {
      this.getUser()
      this.depositHistory()
      this.getvipHistory()
      this.goodsorderList()
      this.getHireOrder()
    } else {
      this.$router.push({ path: '/memberList' })
    }
  },
  methods: {
    // 切换tab
    handleClick(val) {
      console.log(val.name)
    },
    // 会员详情
    async getUser() {
      const res = await this.axios.get(`cashier/user_vip/${this.uid}`)
      if (res.code === 200) {
        this.user = res.data
        console.log(this.user)
      }
    },
    // 押金历史
    async depositHistory() {
      const res = await this.axios.get(`cashier/user_vip_log?user_id=${this.uid}&limit=1000`)
      if (res.code === 200) {
        if (res.data.list.length <= 0) return
        let typeText
        res.data.list.forEach(el => {
          // typeText = this.depositType[el.type - 1]
          el.content = el.msg + ' ' + el.num
          el.timestamp = el.created_at
          el.typeIcon = el.type === 1 ? 'primary' : ''
          el.icon = el.type === 1 ? 'el-icon-more' : ''
          el.color = el.type === 1 ? '' : '#F56C6C'
        });
        this.activities = res.data.list
      }
    },
    // 押金充值
    recharge() {
      this.$prompt('请输入充值金额', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        this.editdeposit(value)
      }).catch(() => {
        this.$message({ type: 'info', message: '取消充值' });
      });
    },
    async editdeposit(value) {
      const res = await this.axios.post('cashier/chang/deposit', {
        user_id: this.uid,
        type: 1,
        deposit: value,
        message: '充值'
      })
      if (res.code === 200) {
        this.getUser()
        this.depositHistory()
        this.$message({ type: 'success', message: '成功充值: ' + value });
      } else {
        this.$message.error(res.message);
      }
    },
    // vip续费历史
    async getvipHistory() {
      const res = await this.axios.get(`cashier/user_vip_date?user_id=${this.uid}`)
      if (res.code === 200) {
        res.data.list.forEach(el => {
          el.typeIcon = 'primary'
          el.icon = 'el-icon-more'
          el.content = `续费${el.year}年`
        })
        this.vipHistory = res.data.list
      }
    },
    // 续费会员
    renew() {
      this.$prompt('请输入续费时间（年）', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        this.renewalMember(value)
      }).catch(() => {
        this.$message({ type: 'info', message: '取消充值' });
      });
    },
    async renewalMember(value) {
      const res = await this.axios.post(`cashier/user_vip/renew/${this.uid}`, {
        year: value
      })
      if (res.code === 200) {
        this.$message({ type: 'success', message: '成功续费: ' + value + '年' });
        this.getUser()
      } else {
        this.$message.error(res.message);
      }
    },



    // 翻页
    chageSize(page) {

    },
    // 订购订单
    async goodsorderList() {
      const res = await this.axios.get(`cashier/user/buy_order?user_id=${this.uid}&page=${this.page}&limit=6`)
      if (res.code === 200) {
        this.goodsorderTab = res.data.list
        this.total = res.data.meta.total
      }
    },
    // 订单详情
    clickorderDetails() { },
    // 修改订单
    editorderClick() { },

    // 租赁订单
    async getHireOrder() {
      const res = await this.axios.get(`cashier/hire?limit=6&page=1&user_id=${this.uid}`)
      console.log(res)
      if (res.code === 200) {
        this.HireOrderTab = res.data.list
      }
    },
    // 租赁订单详情
    clickHireOrderDetails(row) {
      console.log(row)
      this.hireOrderDetails = row.info
      this.dialogTableVisible = true
    },
    returning(row) {
    },
    hireGoods() {
      this.$router.push({ path: `/hire?phone=${this.user.phone}` })
    }
  }
}
</script>


<style lang="less" scoped>
.memberDetails {
  .member-header {
    padding-top: 40px;
    display: flex;
    margin-bottom: 20px;
    .member-info {
      display: flex;
      line-height: 25px;
      .img-box {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 20px;
        img {
          height: 100px;
        }
      }
    }
    ul {
      display: flex;
      li {
        text-align: center;
        width: 200px;
        line-height: 25px;
        .el-button {
          margin-top: 10px;
        }
      }
    }
  }
  .my-label {
    background: #e1f3d8;
  }
  /deep/.el-tabs__content {
    padding-left: 5px;
  }
}
</style>