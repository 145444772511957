<template>
  <div class="orderDetails">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/management' }">订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>订单详情</el-breadcrumb-item>
    </el-breadcrumb>
    <p class="orderNo">订单号 : {{ order.no }}</p>
    <el-divider></el-divider>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="created_at" label="日期"> </el-table-column>
      <el-table-column prop="name" label="名称"> </el-table-column>
      <el-table-column prop="deposit" label="冻结押金"> </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status == 1">进行中</el-tag>
          <el-tag type="success" v-else>已完成</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="商品图片">
        <template slot-scope="scope">
          <img class="goods-img" :src="scope.row.image" alt="" />
        </template>
      </el-table-column>
      <el-table-column label="留存照片">
        <template slot-scope="scope">
          <img class="goods-img" v-for="item in scope.row.give_image" :src="item" alt="" />
        </template>
      </el-table-column>
      <el-table-column label="归还照片">
        <template slot-scope="scope">
          <img class="goods-img" v-for="item in scope.row.imageList" :src="item" alt="" />
        </template>
      </el-table-column>
      <el-table-column label="操作" width="300">
        <template slot-scope="scope">
          <el-button-group>
            <el-button type="primary" icon="el-icon-camera-solid" size="mini" @click="photographGoods(scope.row)" v-if="scope.row.status == 1">拍照</el-button>
            <el-button type="success" @click="giveback(scope.row)" icon="el-icon-upload2" size="mini" v-if="scope.row.status == 1">还玩具</el-button>
            <el-button @click="deduct(scope.row)" size="mini" v-if="scope.row.status == 1">扣除押金</el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <!-- 拍照 -->
    <el-dialog :visible.sync="dialogCamera" width="70%">
      <div class="content" v-loading="loading">
        <video ref="video" width="400" height="300" autoplay></video>
        <canvas ref="canvas" width="400" height="300"></canvas>
      </div>
      <div class="ul">
        <transition name="el-zoom-in-center" v-for="(item, index) in imageList" :key="index">
          <div class="img-box">
            <img :src="item" alt="" />
          </div>
        </transition>
      </div>
      <div class="btn-box">
        <el-button @click="photograph">拍摄</el-button>
        <el-button type="success" @click="submitPhotog">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: this.getQueryValue('id'),
      order: {},
      tableData: [],
      goods_id: '',
      imageList: [],
      dialogCamera: false,
      loading: false,
      buckle_deposit: 0,
    }
  },
  created() {
    this.getOrderDetails()
  },
  methods: {
    async getOrderDetails() {
      const res = await this.axios.get(`cashier/hire/${this.id}`)
      console.log(res)
      if (res.code === 200) {
        this.order = res.data
        this.tableData = this.order.info
      }
    },
    async giveback(row) {
      const res = await this.axios.post(`cashier/back/hire/${this.order.no}`, {
        back_images: row.imageList,
        buckle_deposit: this.buckle_deposit,
        hire_info_id: row.id,
      })
      console.log(res)
      if (res.code === 200) {
        this.$message.success('成功')
        this.getOrderDetails()
      } else {
        this.$message.error('提交失败')
      }
    },
    deduct() {
      this.$prompt('请输入扣除金额', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(({ value }) => {
          this.buckle_deposit = value
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入',
          })
        })
    },
    photographGoods(row) {
      console.log(row)
      this.goods_id = row.id
      this.dialogCamera = true
      this.imageList = []
      this.callCamera()
    },
    // 拍摄照片
    photograph() {
      this.loading = true
      let ctx = this.$refs['canvas'].getContext('2d')
      ctx.drawImage(this.$refs['video'], 0, 0, 400, 300)
      let imgBase64 = this.$refs['canvas'].toDataURL('image/jpeg')
      this.uploadImage(imgBase64)
    },
    // 拍照上传
    async uploadImage(imgBase64) {
      const res = await this.axios.post('cashier/base64/upload', {
        image: imgBase64,
      })
      if (res.code === 200) {
        this.$message.success('成功')
        this.imageList.push(res.data)
        this.loading = false
      } else {
        this.$message.error('失败！请从新拍照')
      }
    },
    // 保存照片
    submitPhotog() {
      this.tableData.some((item, index) => {
        if (item.id === this.goods_id) {
          this.$set(this.tableData[index], 'imageList', this.imageList)
          this.dialogCamera = false
          return true
        }
      })
    },
    // 打开摄像头
    callCamera() {
      this.loading = true
      navigator.mediaDevices
        .getUserMedia({
          video: true,
        })
        .then((success) => {
          this.$refs['video'].srcObject = success
          this.$refs['video'].play()
          this.loading = false
        })
        .catch((error) => {
          console.error('请检查摄像头是否可用')
        })
    },
  },
}
</script>

<style lang="less" scoped>
.orderDetails {
  .orderNo {
    margin-top: 20px;
  }
  .goods-img {
    width: 40px;
    float: left;
    margin-right: 5px;
  }
}
</style>
