<template>
  <div class="memberList">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>会员管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="select">
      <el-form ref="form" :model="selectForm" :inline="true" size="mini">
        <el-form-item label="手机号">
          <el-input v-model="selectForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="会员名">
          <el-input v-model="selectForm.name"></el-input>
        </el-form-item>
        <el-form-item label="vip时间段">
          <el-date-picker :unlink-panels='true' v-model="selectForm.time" value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
          <el-button type="success" @click="addMember">添加会员</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" style="width: 100%" stripe v-loading="loading" element-loading-spinner="el-icon-loading">
      <el-table-column prop="name" label="用户昵称" fixed>
      </el-table-column>
      <el-table-column prop="isVip" label="是否vip">
      </el-table-column>
      <el-table-column prop="vipStartTime" label="开卡日期" width="150">
      </el-table-column>
      <el-table-column prop="vipEndTime" label="到期日期" width="150">
      </el-table-column>
      <el-table-column prop="phone" label="联系方式">
      </el-table-column>
      <el-table-column prop="hire_count" label="租赁订单">
      </el-table-column>
      <el-table-column prop="buy_count" label="购买订单">
      </el-table-column>
      <el-table-column prop="sell_count" label="出售订单">
      </el-table-column>
      <el-table-column prop="deposit" label="押金">
      </el-table-column>
      <el-table-column label="操作" width="350" fixed="right">
        <template slot-scope="scope">
          <el-button-group>
            <el-button type="primary" @click="clickDetails(scope.row)" icon="el-icon-share" size="mini">查看详情</el-button>
            <el-button type="success" @click="editClick(scope.row)" icon="el-icon-edit" size="mini">修改押金</el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini">删除会员</el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination :page-size="10" layout="prev, pager, next" :total="total" @current-change='chageSize'>
    </el-pagination>
    <el-dialog title="添加会员" :visible.sync="dialogVisible" width="50%">
      <el-form label-position="left" label-width="80px" :model="addForm">
        <el-form-item label="介绍人">
          <el-input v-model="addForm.introducePhone">
          </el-input>
          <el-button type="primary" size="mini" @click="testingUser">验证</el-button>
          <el-button :type="typeBtn" size="mini" :icon="typeBtnIcon" v-if="showMessage">{{username}}</el-button>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="addForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="vip日期">
          <el-date-picker :unlink-panels='true' v-model="addForm.time" value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="押金充值">
          <el-input v-model="addForm.deposit"></el-input>
        </el-form-item>
      </el-form>
      <ul class="fukuantype">
        <li>
          <img src="../../assets/images/01.png" alt="">
        </li>
        <li>
          <img src="../../assets/images/02.png" alt="">
        </li>
        <li>
          <img src="../../assets/images/03.png" alt="">
        </li>
      </ul>
      <el-radio-group v-model="pay_type">
        <el-radio :label="3">支付宝</el-radio>
        <el-radio :label="2">微信</el-radio>
        <el-radio :label="1">现金</el-radio>
      </el-radio-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改押金" :visible.sync="increaseordecrease" width="50%">
      <el-form label-position="left" label-width="80px" :model="editDepositForm">
        <el-form-item label="原因">
          <el-input v-model="editDepositForm.msg"></el-input>
        </el-form-item>
        <el-form-item label="金额">
          <el-input v-model="editDepositForm.deposit" type="number"></el-input>
        </el-form-item>
        <el-form-item>
          <el-radio-group v-model="editDepositForm.resource">
            <el-radio label="2">减押金</el-radio>
            <el-radio label="1">加押金</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="editdeposit">确定</el-button>
          <el-button>取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>


<script>
export default {
  data() {
    return {
      selectForm: {
        phone: '',
        name: '',
        time: ''
      },
      addForm: {
        introducePhone: '',
        phone: '',
        name: '',
        time: '',
        deposit: ''
      },
      showMessage: false,
      username: '',
      typeBtn: '',
      typeBtnIcon: '',
      recommend_uid: '',
      tableData: [],
      limit: 10,
      page: 1,
      total: 0,
      loading: true,
      // 添加会员
      dialogVisible: false,
      pay_type: 3,
      // 修改押金
      uid: "",
      increaseordecrease: false,
      editDepositForm: {
        resource: '1',
        msg: '',
        deposit: ''
      }
    }
  },
  created() {
    this.getmemberList()
  },
  methods: {
    onSubmit() {
      this.page = 1
      this.getmemberList()
    },
    async getmemberList() {
      this.tableData = []
      const res = await this.axios.get(`cashier/user_vip?limit=${this.limit}&page=${this.page}&name=${this.selectForm.name}&phone=${this.selectForm.phone}&vip_date=${this.selectForm.time}`)
      if (res.code === 200) {
        res.data.list.forEach(el => {
          if (el.vip) {
            el.isVip = '是'
            el.vipStartTime = el.vip.start_time.split(' ')[0]
            el.vipEndTime = el.vip.end_time.split(' ')[0]
            el.deposit = el.vip.deposit
          } else {
            el.isVip = '否'
          }
        });
        this.tableData = res.data.list
        this.total = res.data.meta.total
        this.loading = false
        console.log(res)
      }
    },
    handleClick(row) {
      console.log(row)
      this.$confirm('请确认此会员以归还所有玩具, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    editClick(row) {
      this.uid = row.id
      this.increaseordecrease = true
    },
    async editdeposit() {
      const res = await this.axios.post('cashier/chang/deposit', {
        user_id: this.uid,
        type: this.editDepositForm.resource,
        deposit: this.editDepositForm.deposit,
        message: this.editDepositForm.msg
      })
      if (res.code === 200) {
        this.$message.success('修改成功');
        this.increaseordecrease = false
        this.getmemberList()
      } else {
        this.$message.error(res.message);
      }
    },
    clickDetails(row) {
      console.log(row)
      this.$router.push({ path: `/memberDetails?id=${row.id}` })

    },
    chageSize(page) {
      console.log(page)
      this.page = page
      this.loading = true
      this.getmemberList()
    },
    addMember() {
      this.dialogVisible = true
    },
    async testingUser() {
      const res = await this.axios.get(`cashier/user/recommend/phone?phone=${this.addForm.introducePhone}`)
      console.log(res)
      if (res.code === 200) {
        // el-icon-check el-icon-close
        this.typeBtn = 'success'
        this.showMessage = true
        this.typeBtnIcon = 'el-icon-check'
        this.username = res.data.name ? res.data.name : '默认名称'
        this.recommend_uid = res.data.id
      } else {
        this.typeBtn = 'danger'
        this.showMessage = true
        this.typeBtnIcon = 'el-icon-close'
        this.username = res.message
      }
    },
    async submit() {
      const res = await this.axios.post('cashier/user_vip', {
        name: this.addForm.name,
        start_time: this.addForm.time[0],
        end_time: this.addForm.time[1],
        deposit: this.addForm.deposit,
        phone: this.addForm.phone,
        pay_type: this.pay_type,
        recommend_uid: this.recommend_uid
      })
      if (res.code === 200) {
        this.dialogVisible = false
        this.$message.success('会员开通成功');
        this.page = 1
        this.getmemberList()
      } else {
        this.$message.error(res.message);
      }
    }
  }
}
</script>


<style lang="less" >
.memberList {
  .el-table {
    .el-table__cell {
      text-align: center;
    }
  }
  .el-breadcrumb {
    margin-bottom: 20px;
  }
  .el-date-editor--monthrange.el-input,
  .el-date-editor--monthrange.el-input__inner {
    width: 415px;
  }
  .vip {
    margin-top: 20px;
  }
  .fukuantype {
    display: flex;
    margin-top: 30px;
    li {
      margin-right: 35px;
      padding-left: 5px;
      img {
        width: 50px;
      }
    }
  }
}
</style>