<template>
  <div class="management">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>订单管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="select">
      <el-form ref="form" :model="selectForm" :inline="true" size="mini">
        <el-form-item label="会员名">
          <el-input v-model="selectForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="selectForm.name"></el-input>
        </el-form-item>
        <el-form-item label="时间段">
          <el-date-picker :unlink-panels="true" v-model="selectForm.time" value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-button-group>
      <el-button type="primary" icon="el-icon-arrow-left" @click="$router.push({ path: '/hire' })">租玩具</el-button>
      <!-- <el-button type="primary">买玩具<i class="el-icon-arrow-right el-icon--right"></i></el-button> -->
    </el-button-group>
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column type="index" label="序号" width="80" align="center"> </el-table-column>
      <el-table-column prop="name" label="会员名"> </el-table-column>
      <el-table-column prop="phone" label="手机号"> </el-table-column>
      <el-table-column prop="no" label="订单号"> </el-table-column>
      <el-table-column prop="address" label="玩具">
        <template slot-scope="scope">
          <img class="goods-img" v-for="item in scope.row.info" :src="item.image" alt="" />
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 1">进行中</el-tag>
          <el-tag type="success" v-else>已完成</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="冻结押金"> </el-table-column>
      <el-table-column prop="address" label="操作员"> </el-table-column>
      <el-table-column label="操作" width="350" fixed="right">
        <template slot-scope="scope">
          <el-button-group>
            <el-button type="primary" @click="clickDetails(scope.row)" icon="el-icon-share" size="mini">查看详情</el-button>
            <el-button type="success" @click="clickDetails(scope.row)" icon="el-icon-upload2" size="mini">还玩具</el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination :page-size="10" layout="prev, pager, next" :total="total" @current-change="chageSize"></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectForm: {
        name: '',
        phone: '',
        time: '',
      },
      tableData: [1],
      total: 0,
      limit: 8,
      page: 1,
    }
  },
  created() {
    this.getOrderList()
  },
  methods: {
    onSubmit() {},
    clickDetails(row) {
      this.$router.push({ path: `/orderDetails?id=${row.id}` })
    },
    chageSize(val) {
      this.page = val
      this.getOrderList()
    },
    async getOrderList() {
      const res = await this.axios.get(`cashier/hire?limit=${this.limit}&page=${this.page}&phone=${this.selectForm.phone}`)
      if (res.code === 200) {
        console.log(res)
        const { list, meta } = res.data
        this.total = meta.total
        list.forEach((el, index) => {
          el.name = el.user.name
          el.phone = el.user.phone
        })
        this.tableData = list
      }
    },
  },
}
</script>

<style lang="less">
.management {
  .select {
    padding-top: 20px;
  }
  .goods-img {
    width: 40px;
    float: left;
  }
}
</style>
