<template>
  <div class="something">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>还玩具</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form :inline="true" :model="formInline" class="demo-form-inline" size="small">
      <el-form-item label="会员名称">
        <el-input v-model="formInline.name" placeholder="会员名称"></el-input>
      </el-form-item>
      <el-form-item label="手机号">
        <el-input v-model="formInline.phone" placeholder="手机号"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="HireOrderTab" stripe style="width: 100%">
      <el-table-column type="index" label="序号" width="80">
      </el-table-column>
      <el-table-column prop="created_at" label="日期">
      </el-table-column>
      <el-table-column prop="no" label="订单号">
      </el-table-column>
      <el-table-column label="玩具">
        <template slot-scope="scope">
          <img v-for="item in scope.row.info" :src="item.image" class="goods-img">
        </template>
      </el-table-column>
      <el-table-column label="项目状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 1">进行中</el-tag>
          <el-tag v-else-if="scope.row.status === 2" type="success">已完结</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="store.username" label="操作员">
      </el-table-column>
      <el-table-column prop="" label="操作" width="350" fixed="right">
        <template slot-scope="scope">
          <el-button-group>
            <el-button type="success" size="mini" v-if="scope.row.status === 1" @click="huanwanju(scope.row)">还玩具</el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formInline: {
        phone: '18705204931',
        name: '',
      },
      HireOrderTab: [],
      limit: 6,
      page: 1
    }
  },
  methods: {
    clickHireOrderDetails() { },
    async onSubmit() {
      const res = await this.axios.get(`cashier/hire?limit=${this.limit}&page=${this.page}&phone=${this.formInline.phone}`)
      if (res.code === 200) {
        console.log(res)
        this.HireOrderTab = res.data.list
      } else {

      }
    },
    huanwanju(row) {
      console.log(row)
      localStorage.setItem('orderInfo', JSON.stringify(row))
      this.$router.push({ path: `/orderReturn?id=${row.id}` })
    }
  }
}
</script>









<style lang="less" scoped>
.something {
  .el-form {
    margin-top: 25px;
  }
  .goods-img {
    width: 50px;
  }
}
</style>