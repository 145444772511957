<template>
  <div class="index">
    <el-container>
      <el-header>Header</el-header>
      <el-container>
        <el-aside width="200px">
          <el-menu :default-active="active" class="el-menu-vertical-demo" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b" unique-opened router>
            <!-- <el-menu-item index="home">
              <i class="el-icon-menu"></i>
              <span slot="title">首页</span>
            </el-menu-item> -->
            <el-menu-item index="hire">
              <i class="el-icon-shopping-cart-full"></i>
              <span slot="title">租玩具</span>
            </el-menu-item>
            <el-menu-item index="something">
              <i class="el-icon-shopping-cart-1"></i>
              <span slot="title">还玩具</span>
            </el-menu-item>
            <el-menu-item index="memberList">
              <i class="el-icon-setting"></i>
              <span slot="title">会员管理</span>
            </el-menu-item>
            <el-menu-item index="management">
              <i class="el-icon-setting"></i>
              <span slot="title">订单管理</span>
            </el-menu-item>
          </el-menu>
        </el-aside>
        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>


<script>
export default {
  data() {
    return {
    }
  },
  computed: {
    active() {
      return this.$route.path.slice(1).split("-")[0];
    }
  },

}
</script>


<style lang="less" scoped>
.index {
  height: 100vh;
  .el-container {
    height: 100%;
  }
  .el-header,
  .el-footer {
    background-color: #b3c0d1;
    color: #333;
    text-align: center;
    line-height: 60px;
  }
  .el-main {
    margin: 0;
  }

  .el-aside {
    background-color: rgb(84, 92, 100);
    color: #333;
    text-align: center;
    height: 100%;
    .el-menu {
      border-right: solid 1px rgb(84, 92, 100);
    }
  }
}
</style>